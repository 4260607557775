import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        signedInUser: null,
        userAccounts: null,
        defaultAccount: null,
        selectedAccount: null
    },
    mutations:{
        SET_SIGNED_IN_USER(state, data){
            state.signedInUser = data;
        },
        SET_USER_ACCOUNTS(state, data){
            state.userAccounts = data;
        },
        SET_SELECTED_ACCOUNT(state, data){
            state.selectedAccount = data;
        }
    },
    getters:{
        accounts(state){
            if(state.userAccounts){
                return state.userAccounts;
            }
            else{
                return [];
            }
        },
        defaultAccount(state){
            return state.defaultAccount;
        },
        selectedAccount(state){
            return state.selectedAccount;
        }
    },
    actions:{
        getAccounts({commit, state}){
            const params = {body: {
                clientName : state.signedInUser.username.split("-")[1],
                emailAddress : state.signedInUser.attributes.email             
            }}
            API.post("peoplePortalRestAPI", "/accounts", params).then((res) => {
                if(res.status === "200"){
                    commit("SET_USER_ACCOUNTS", res.data)
                }
            })
        }
    }
}